// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFunctions, httpsCallable } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIesR9fel3zo4f8f2r_Db-JQVher_YHig",
  authDomain: "school-risingstars.firebaseapp.com",
  projectId: "school-risingstars",
  storageBucket: "school-risingstars.firebasestorage.app",
  messagingSenderId: "897468493925",
  appId: "1:897468493925:web:34725c6eb28f42161b168c",
  measurementId: "G-KZXJEKMVNT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
export { functions };
