import React, { useState } from "react";

const PdfUpload = () => {
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file.");
      return;
    }

    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64Data = reader.result.split("base64,")[1];
      const fileData = {
        base64: base64Data,
        type: file.type,
        name: file.name,
      };

      try {
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbwWMJXZbHkU8QIMrZM9kERUfNG_pvDfxdZtpduubxVt-_WeR7v_ZCxx9YU7GyxywYQj/exec", // Replace with your script URL
          {
            method: "POST",
            body: JSON.stringify(fileData),
          }
        );

        const result = await response.text();
        setStatus(result);
        console.log(result);
      } catch (error) {
        setStatus("Upload failed!");
        console.error("Error:", error);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload PDF</button>
      <p>{status}</p>
    </div>
  );
};

export default PdfUpload;
