import React from "react";
import JobListing from "./JobCard";

function l̥() {
  return (
    <div>
      <p>vacancies</p>
      <JobListing />
    </div>
  );
}

export default l̥;
