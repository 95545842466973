import React from "react";
import "../styles/Updates.css";

const Updates = () => {
  const updates = [
    {
      id: 1,
      text: "Vacancies for School Receptionist. Apply Now!",
      link: "/vacancies",
    },
    {
      id: 2,
      text: "Admissions for 2025-2026 start from January 2025 for Nursery - Class 8. Secure your seat today!",
      link: "/admissions",
    },
    {
      id: 3,
      text: "Upcoming Parent-Teacher Meeting scheduled for December 15th, 2024. Please RSVP.",
      link: "/parent-teacher-meeting",
    },
    {
      id: 4,
      text: "Winter Holiday Notice: School will be closed from December 20th, 2024 to January 2nd, 2025.",
      link: "/holiday-notice",
    },
    {
      id: 5,
      text: "School Annual Day 2024 - Save the date! December 25th, 2024. Don't miss the event.",
      link: "/annual-day",
    },
    {
      id: 6,
      text: "Download our School App for easy access to class schedules, announcements, and more!",
      link: "/download-app",
    },
    {
      id: 7,
      text: "School Fee Payment for the upcoming semester is due by January 5th, 2025. Please make the payment.",
      link: "/fee-payment",
    },
    {
      id: 8,
      text: "New Sports Activities introduced this year: Football, Basketball, and Swimming. Enroll now.",
      link: "/sports-activities",
    },
    {
      id: 9,
      text: "Student Counseling Services available for grades 6 to 12. Book an appointment with our counselors.",
      link: "/counseling-services",
    },
    {
      id: 10,
      text: "Exciting New Library Books for 2025! Check out the latest additions and expand your reading list.",
      link: "/library-updates",
    },
  ];

  return (
    <div className="updates-container">
      <h3>Latest Updates</h3>
      <div className="updates-list-wrapper">
        <ul className="updates-list">
          {updates.map((update) => (
            <li key={update.id} className="update-item">
              <a href={update.link}>{update.text}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Updates;
