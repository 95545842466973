import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Collapse,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebaseConfig";

// Sample data for the job listings
const jobData = [
  {
    position: "School Receptionist",
    location:
      "Rising Stars Public School, Rampur-Patwai Road, Near Bharat Petrol Pump, Patwai, Rampur",
    eligibility: {
      qualifications: "Minimum Bachelor’s degree",
      experience: [
        "At least 1-2 years of experience in a receptionist or administrative role, preferably in an educational environment.",
        "Freshers with excellent communication skills and relevant certifications will be considered.",
      ],
      skills: [
        "Strong verbal and written communication skills in English and Hindi",
        "Proficiency in using Microsoft office software (e.g., Word, Excel)",
        "Ability to operate standard office equipment such as telephones, fax machines, and printers",
        "Knowledge of basic administrative procedures and school policies (preferred)",
        "Knowledge of office management and basic bookkeeping",
        "Strong organizational and multitasking abilities",
      ],
    },
  },
  {
    position: "School Teacher",
    location:
      "Rising Stars Public School, Rampur-Patwai Road, Near Bharat Petrol Pump, Patwai, Rampur",
    eligibility: {
      qualifications: "",
      experience: ["Coming soon. Please stay tuned for updates."],
      skills: [""],
    },
  },
  {
    position: "HM",
    location:
      "Rising Stars Public School, Rampur-Patwai Road, Near Bharat Petrol Pump, Patwai, Rampur",
    eligibility: {
      qualifications: "",
      experience: ["Coming soon. Please stay tuned for updates."],
      skills: [""],
    },
  },
  // Add more job data objects as needed
];

const JobListing = () => {
  const [position, setPosition] = useState(0); // Change to null to start with no job selected
  const [formOpen, setFormOpen] = useState(false); // State to handle the modal visibility
  const [selectedIndex, setselectedIndex] = useState(0);
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    dob: "",
    currentAddress: "",
    previousAddress: "",
    phoneNumber: "",
    bachelorDegree: "", // Update default value for bachelorDegree to be a string
    cv: null,
    microsoftOfficeExperience: "", // Update default value for microsoftOfficeExperience to be a string
  });

  const showJobDetails = (index) => {
    if (position === index) {
      setPosition(null); // If the same job is clicked again, toggle visibility
    } else {
      setPosition(index); // Show details for the clicked job
      setselectedIndex(index);
    }
  };

  const handleApplyNow = () => {
    setFormOpen(true); // Open the modal with the form
  };

  const handleCloseForm = () => {
    setFormOpen(false); // Close the modal
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      cv: file,
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  // const handleSubmitForm = async (e) => {
  //   e.preventDefault();

  //   try {
  //     console.log("Starting form submission...");

  //     // Validate form data
  //     if (
  //       !formData.email ||
  //       !formData.fullName ||
  //       !formData.dob ||
  //       !formData.cv
  //     ) {
  //       alert("Please fill out all required fields.");
  //       console.error("Validation failed: Missing required fields.");
  //       return;
  //     }

  //     console.log("Form data validated:", formData);

  //     // Construct the payload for Google Forms
  //     const formPayload = new FormData();
  //     formPayload.append("entry.750759436", formData.fullName); // Replace with your actual field name for fullName
  //     formPayload.append("entry.1118984347", formData.email); // Replace with your actual field name for email
  //     formPayload.append("entry.1936654261", formData.phoneNumber); // Replace with your actual field name for phoneNumber
  //     formPayload.append("entry.2050917216", formData.currentAddress); // Replace with your actual field name for currentAddress
  //     formPayload.append("entry.487397617", formData.previousAddress); // Replace with your actual field name for previousAddress
  //     formPayload.append("entry.332900874", formData.bachelorDegree); // Replace with your actual field name for bachelorDegree
  //     formPayload.append("entry.166310938", formData.microsoftOfficeExperience); // Replace with your actual field name for microsoftOfficeExperience
  //     formPayload.append("entry.2121784405", formData.dob); // Replace with your actual field name for phoneNumber

  //     // Handle file input (e.g., CV)
  //     if (formData.cv) {
  //       formPayload.append("entry.9012345678", formData.cv); // Replace with your actual field name for CV upload
  //     }

  //     console.log("Constructed form payload.");

  //     // Google Form action URL
  //     const formActionURL =
  //       "https://docs.google.com/forms/d/e/1FAIpQLScf9AM5Q1pSSdyNJtJiPK5hVys6ay3N7Eul1ZDFRBvoXUUoaA/formResponse";

  //     console.log("Form action URL:", formActionURL);

  //     // Submit the form using axios
  //     const response = await axios.post(formActionURL, formPayload, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     console.log("Response from Google Form submission:", response);

  //     if (response.status === 200 || response.status === 302) {
  //       alert("Form submitted successfully!");
  //       console.log("Form submitted successfully.");
  //       handleCloseForm(); // Close the modal after successful submission
  //     } else {
  //       alert("Failed to submit the form. Please try again.");
  //       console.error("Form submission failed with status:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting the form:", error);
  //     alert(
  //       "An error occurred while submitting the form. Please check the console for more details."
  //     );
  //   }
  // };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const file = formData.cv;

    if (
      !formData.email ||
      !formData.fullName ||
      !formData.dob ||
      !formData.cv
    ) {
      alert("Please fill out all required fields.");
      console.error("Validation failed: Missing required fields.");
      return;
    }

    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64Data = reader.result.split("base64,")[1];
      const fileData = {
        base64: base64Data,
        type: file.type,
        name: file.name,
      };

      try {
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbxuZWv4l3SUy6GLphaqs7rqvg4pBB5zMdxI0KnXdxpnLP2YJAGReC1cdGvdoTNyZIw/exec", // Replace with your script URL
          {
            method: "POST",
            body: JSON.stringify(fileData),
          }
        );

        const result = await response.text();
        if (response.ok) {
          // Prepare the data to be sent to Google Apps Script
          const data = {
            Email: formData.email,
            FullName: formData.fullName,
            DOB: formData.dob,
            CurrentAddress: formData.currentAddress,
            PreviousAddress: formData.previousAddress,
            PhoneNumber: formData.phoneNumber,
            BachelorDegree: formData.bachelorDegree,
            MicrosoftOfficeExperience: formData.microsoftOfficeExperience,
            CV: result,
          };

          try {
            // Make a POST request to Google Apps Script web URL
            const response = await fetch(
              "https://script.google.com/macros/s/AKfycbzu7k9paGxEGZh9fDyZxnBo3TUGuk5R_mB_R6O8uPwwbeH19DMUYLH7uqRvUza3DE-R/exec",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams(data).toString(),
              }
            );

            const result = await response.text();

            if (response.ok) {
              alert("Form submitted successfully!");
              console.log(result);
              handleCloseForm(); // Close the modal after successful submission
            } else {
              alert("Failed to submit the form. Please try again.");
              console.error("Form submission failed:", result);
            }
          } catch (error) {
            console.error("Error submitting the form:", error);
            alert(
              "An error occurred while submitting the form. Please check the console for more details."
            );
          }
        }
        console.log(result);
      } catch (error) {
        alert("Upload failed!");
        console.error("Error:", error);
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div>
      <Grid container spacing={2}>
        {/* Job Listings Column */}
        <Grid
          item
          xs={4}
          md={4}
          sx={{
            borderRight: "1px solid blue",
            maxHeight: "80vh", // Adjust the height as needed
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            {jobData.map((job, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    maxHeight: "200px",
                    margin: "3px ",
                    padding: "10px",
                    border: "1px solid gray",
                    backgroundColor:
                      selectedIndex === index ? "lightcyan" : "white",
                  }}
                  onClick={() => showJobDetails(index)}
                >
                  <CardContent>
                    <Typography
                      variant="h7"
                      component="div"
                      sx={{ fontWeight: "bold", textAlign: "left" }}
                    >
                      {job.position}
                    </Typography>

                    <Typography
                      variant="body"
                      sx={{
                        marginTop: 3,
                        textAlign: "center",
                        color: "gray",
                        fontSize: {
                          xs: "12px", // For extra small screens (mobile)
                          sm: "13pz", // For small screens (tablet)
                          md: "14px", // For medium screens (laptop)
                          lg: "1rem", // For large screens (desktop)
                        },
                        lineHeight: "10px",
                      }}
                    >
                      {job.eligibility.experience}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Job Details Column */}
        <Grid
          item
          xs={7}
          sm={7}
          md={7}
          sx={{
            margin: "6px",
            maxHeight: "80vh", // Adjust the height as needed
            overflowY: "auto",
            scrollbarWidth: "none", // Hide scrollbar in Firefox
          }}
        >
          <Collapse in={position !== null}>
            <Typography
              variant="h7"
              sx={{
                fontWeight: "bold",
                fontSize: {
                  xs: "1rem", // For extra small screens (mobile)
                  sm: "1.2rem", // For small screens (tablet)
                  md: "1.5rem", // For medium screens (laptop)
                  lg: "1.8rem", // For large screens (desktop)
                },
                marginTop: 1,
              }}
            >
              Educational Qualifications:
            </Typography>
            <Typography variant="body1">
              {position !== null &&
                jobData[position].eligibility.qualifications}
            </Typography>

            <Typography variant="h7" sx={{ fontWeight: "bold", marginTop: 1 }}>
              Experience:
            </Typography>
            {position !== null &&
              jobData[position].eligibility.experience.map((exp, index) => (
                <Typography key={index} variant="body1">
                  {exp}
                </Typography>
              ))}

            <Typography variant="h7" sx={{ fontWeight: "bold", marginTop: 1 }}>
              Skills:
            </Typography>
            {position !== null &&
              jobData[position].eligibility.skills.map((skill, index) => (
                <Typography key={index} variant="body1">
                  {skill}
                </Typography>
              ))}
            <Divider sx={{ my: 2 }} />
            {jobData[position].eligibility.experience.some(
              (exp) => exp !== "Coming soon. Please stay tuned for updates."
            ) && (
              <Button
                variant="contained"
                onClick={handleApplyNow}
                sx={{ marginTop: 2 }}
              >
                Apply Now
              </Button>
            )}
          </Collapse>
        </Grid>
      </Grid>
      <Dialog
        open={formOpen}
        onClose={handleCloseForm}
        fullWidth // This makes the dialog take up the full width
        maxWidth="md"
      >
        <DialogTitle>Job Application Form</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Date of Birth"
              name="dob"
              value={formData.dob}
              onChange={handleInputChange}
              fullWidth
              required
            />
            <TextField
              label="Current Address"
              name="currentAddress"
              value={formData.currentAddress}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Previous Address"
              name="previousAddress"
              value={formData.previousAddress}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              fullWidth
            />

            <FormControl component="fieldset">
              <Typography variant="body1">Bachelor Degree:</Typography>
              <RadioGroup
                name="bachelorDegree"
                value={formData.bachelorDegree}
                onChange={handleRadioChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <FormControl component="fieldset">
              <Typography variant="body1">
                Microsoft Office Experience:
              </Typography>
              <RadioGroup
                name="microsoftOfficeExperience"
                value={formData.microsoftOfficeExperience}
                onChange={handleRadioChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <Button
              variant="contained"
              component="label"
              sx={{ marginTop: "10px" }}
            >
              Upload CV
              <input
                type="file"
                name="cv"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                hidden
                required
              />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button type="submit" variant="contained" onClick={handleSubmitForm}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobListing;
